import React from "react";
import { Alert } from "@nextui-org/react";

const SchedulePage = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-24">
      <div className="flex flex-col gap-4 w-full max-w-md">
        <Alert
          color="secondary"
          title={`Esta pagina ainda não foi implementada, volte mais tarde!`}
          variant="bordered"
          className="text-center"
        />
      </div>
    </div>
  );
};

export default SchedulePage;
