import React from "react";
import { Card, CardBody, Button } from "@nextui-org/react";
import { Construction, ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

const NotImplementedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-[80vh] p-4">
      <Card className="max-w-md w-full">
        <CardBody className="flex flex-col items-center gap-6 py-8">
          <Construction className="w-16 h-16 text-secondary" />
          
          <div className="text-center space-y-2">
            <h1 className="text-2xl font-bold text-default-900">
              Ops! Você chegou cedo demais
            </h1>
            <p className="text-default-600">
              Estamos trabalhando duro para construir algo incrível aqui.
              Volte em breve para conferir as novidades!
            </p>
          </div>

          {/* <div className="w-full max-w-[200px] h-2 bg-secondary/20 rounded-full overflow-hidden">
            <div 
              className="h-full bg-secondary animate-pulse rounded-full"
              style={{ width: '60%' }}
            />
          </div>

          <p className="text-default-500 text-sm italic">
            Progresso estimado: 60%
          </p> */}

          <Button
            color="secondary"
            variant="light"
            startContent={<ArrowLeft size={16} />}
            onPress={() => navigate(-1)}
          >
            Voltar
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default NotImplementedPage;