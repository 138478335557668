import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardHeader } from "@nextui-org/react";
import { Heart, Brain, Target, Award, CheckCircle } from "lucide-react";

const HomePage = () => {
  const navigate = useNavigate();

  const services = [
    {
      name: "Psicoterapia",
      description: "Atendimento personalizado para seu bem-estar emocional",
    },
    {
      name: "Avaliação Psicológica",
      description: "Análise completa do perfil psicológico",
    },
    {
      name: "Neuropsicologia",
      description: "Avaliação e reabilitação das funções cognitivas",
    },
    {
      name: "Psiquiatria",
      description: "Diagnóstico e tratamento especializado",
    },
  ];

  return (
    <div className="mx-auto max-w-6xl px-4 py-8 flex flex-col gap-8">
      {/* Hero Section */}
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold bg-gradient-to-r from-secondary to-primary bg-clip-text text-transparent mb-4">
          Psycoplam
        </h1>
        <p className="text-xl text-default-600 mb-6">
          Especialistas em Saúde Mental dedicados ao seu bem-estar
        </p>
        <Button
          size="lg"
          color="secondary"
          variant="shadow"
          onPress={() => navigate("/commingSoon")}
          className="font-semibold"
        >
          Agende sua Consulta
        </Button>
      </div>

      {/* Mission and Values Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <Card className="bg-gradient-to-b from-secondary-50 to-background">
          <CardHeader className="flex gap-3">
            <Heart className="text-secondary" />
            <h2 className="text-xl font-semibold">Nossa Missão</h2>
          </CardHeader>
          <CardBody>
            <p className="text-default-600">
              Promover saúde física e mental, desenvolver pessoas emocionalmente
              para gerar qualidade de vida.
            </p>
          </CardBody>
        </Card>

        <Card className="bg-gradient-to-b from-primary-50 to-background">
          <CardHeader className="flex gap-3">
            <Target className="text-primary" />
            <h2 className="text-xl font-semibold">Nossa Visão</h2>
          </CardHeader>
          <CardBody>
            <p className="text-default-600">
              Ser referência em Saúde Mental, com foco no desenvolvimento
              humano, promovendo recursos psíquicos e responsabilidade social.
            </p>
          </CardBody>
        </Card>

        <Card className="bg-gradient-to-b from-success-50 to-background">
          <CardHeader className="flex gap-3">
            <Award className="text-success" />
            <h2 className="text-xl font-semibold">Nossos Valores</h2>
          </CardHeader>
          <CardBody>
            <p className="text-default-600">
              Ética, Integridade, Empatia, Respeito ao próximo e
              Responsabilidade.
            </p>
          </CardBody>
        </Card>
      </div>

      {/* About Us Section */}
      <Card className="bg-gradient-to-r from-secondary-50/50 to-primary-50/50">
        <CardBody className="text-center py-8">
          <Brain className="w-12 h-12 mx-auto mb-4 text-secondary" />
          <h2 className="text-2xl font-semibold mb-4">Quem Somos</h2>
          <p className="text-default-600 max-w-2xl mx-auto">
            Possuímos uma rede de profissionais especializados na área
            psicológica e psiquiátrica. Realizamos atendimentos com foco na
            promoção da qualidade de vida e Saúde Mental.
          </p>
        </CardBody>
      </Card>

      {/* Services Section */}
      <div className="mt-8">
        <h2 className="text-2xl font-semibold text-center mb-6">
          Nossos Serviços
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {services.map((service) => (
            <Card key={service.name} className="bg-content2/40">
              <CardBody className="flex flex-row items-center gap-4">
                <CheckCircle className="text-primary flex-shrink-0" />
                <div>
                  <h3 className="font-semibold text-lg">{service.name}</h3>
                  <p className="text-default-600">{service.description}</p>
                </div>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <Card className="bg-secondary/10 mt-8">
        <CardBody className="text-center py-8">
          <h2 className="text-2xl font-semibold mb-4">
            Comece Sua Jornada de Bem-estar
          </h2>
          <p className="text-default-600 mb-6">
            Com compromisso social, nosso objetivo é oferecer serviços e
            recursos que proporcionem Saúde Mental.
          </p>
          <Button
            size="lg"
            color="secondary"
            variant="solid"
            onPress={() => navigate("/commingSoon")}
            className="font-semibold"
          >
            Agendar sua consulta
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default HomePage;
