import React from "react";
import { useTheme } from "next-themes";
import { Icon } from "@iconify/react";
import { Button } from "@nextui-org/react";

const ThemeSwitcher = () => {
  const { theme, setTheme } = useTheme("light");

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <Button
      isIconOnly
      variant="ghost"
      onPress={toggleTheme}
      className="flex items-center justify-center"
    >
      {theme === "dark" ? (
        <Icon icon="material-symbols:light-mode" width="20" height="20" />
      ) : (
        <Icon icon="material-symbols:dark-mode" width="20" height="20" />
      )}
    </Button>
  );
};
export default ThemeSwitcher;