import React from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import logoLight from "../assets/psycoplam-Logo.png";
import logoDark from "../assets/psycoplam-Logo-darkMode.png";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Link,
  DropdownItem,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  Avatar,
  Button,
} from "@nextui-org/react";
import { Calendar, Home, LogOut, User } from "lucide-react";
import ThemeSwitcher from "./themeSwitcher";
import { AuthContext } from "../contexts/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useTheme } from "next-themes";

const Header = () => {
  const { theme } = useTheme();
  const logoSrc = theme === "dark" ? logoDark : logoLight;
  const location = useLocation();
  const navigate = useNavigate();
  const { user, role } = React.useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const navigationItems = [
    { path: "/", label: "Home", icon: Home },
    { path: "/commingSoon", label: "Agendar consulta", icon: Calendar },
  ];

  const isActiveRoute = (path) => location.pathname === path;

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.reload();
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  return (
    <Navbar
      onMenuOpenChange={setIsMenuOpen}
      className="bg-background/70 backdrop-blur-md border-b border-divider"
      maxWidth="xl"
      position="sticky"
    >
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <NavbarBrand>
          <Link
            as={RouterLink}
            to="/"
            className="flex items-center gap-2"
            color="foreground"
          >
            <img
              height="36"
              width="36"
              src={logoSrc}
              alt="Psycoplam Logo"
              className="rounded-full"
            />
            <p className="font-bold text-inherit">Psycoplam</p>
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-8" justify="center">
        {navigationItems.map(({ path, label }) => (
          <NavbarItem key={path} isActive={isActiveRoute(path)}>
            <Link
              as={RouterLink}
              to={path}
              color={isActiveRoute(path) ? "secondary" : "foreground"}
              className="font-medium"
            >
              {label}
            </Link>
          </NavbarItem>
        ))}
      </NavbarContent>

      <NavbarContent justify="end" className="gap-2">
        <ThemeSwitcher />

        <Dropdown placement="bottom-end">
          <DropdownTrigger>
            <Avatar
              isBordered
              as="button"
              className="transition-transform"
              color="secondary"
              name={user?.displayName}
              size="sm"
              showFallback
              src={user?.photoURL}
              fallback={<User className="w-4 h-4" />}
            />
          </DropdownTrigger>
          <DropdownMenu aria-label="Profile Actions" variant="flat">
            {user ? (
              <>
                <DropdownItem key="profile" className="h-16 gap-2" isReadOnly>
                  <div className="flex flex-col gap-1">
                    <p className="font-semibold">Bem-vindo,</p>
                    <p className="font-semibold">{user.displayName}</p>
                    {role === "therapist" && (
                      <p className="text-xs text-secondary">Administrador</p>
                    )}
                  </div>
                </DropdownItem>
                {role === "therapist" && (
                  <DropdownItem key="dashboard" as={RouterLink} to="/dashboard">
                    Dashboard
                  </DropdownItem>
                )}
                <DropdownItem
                  key="logout"
                  color="danger"
                  startContent={<LogOut className="w-4 h-4" />}
                  onPress={handleLogout}
                >
                  Sair
                </DropdownItem>
              </>
            ) : (
              <DropdownItem key="login" onPress={() => navigate("/login")}>
                Entrar
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </NavbarContent>

      <NavbarMenu className="pt-6">
        {navigationItems.map(({ path, label, icon: Icon }) => (
          <NavbarMenuItem key={path}>
            <Link
              as={RouterLink}
              to={path}
              color={isActiveRoute(path) ? "secondary" : "foreground"}
              className="w-full flex items-center gap-2"
            >
              <Icon className="w-4 h-4" />
              {label}
            </Link>
          </NavbarMenuItem>
        ))}
        {user && (
          <NavbarMenuItem>
            <Button
              color="danger"
              variant="flat"
              onPress={handleLogout}
              startContent={<LogOut className="w-4 h-4" />}
              className="w-full justify-start"
            >
              Sair
            </Button>
          </NavbarMenuItem>
        )}
      </NavbarMenu>
    </Navbar>
  );
};

export default Header;
