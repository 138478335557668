import React from "react";
import { Link } from "@nextui-org/react";
import { Divider } from "@nextui-org/react";
import { Card, CardBody } from "@nextui-org/react";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const links = [
    { name: "Blog", href: "/" },
    { name: "About", href: "/about" },
    { name: "Contact", href: "/contact" },
    { name: "Privacy", href: "/privacy" },
  ];

  return (
    <Card className="w-full rounded-none shadow-none bg-content1" radius="none">
      <CardBody className="max-w-5xl mx-auto py-8">
        <div className="flex flex-col gap-6">
          {/* Top section */}
          <div className="flex flex-col gap-1">
            <h4 className="text-xl font-semibold">Psycoplam</h4>
            <p className="text-sm text-default-500">Curitiba {currentYear}</p>
          </div>

          <Divider className="my-2" />

          {/* Bottom links */}
          <div className="flex flex-wrap gap-4">
            {links.map((link) => (
              <Link
                key={link.name}
                href={link.href}
                size="sm"
                className="text-default-500 hover:text-primary"
              >
                {link.name}
              </Link>
            ))}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Footer;
