import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
const myEventsList = [
  {
    title: "Meeting with Bob",
    start: new Date(2023, 9, 20, 10, 0), // October is month 9 in JavaScript Date
    end: new Date(2023, 9, 20, 12, 0),
  },
  {
    title: "Lunch with Sarah",
    start: new Date(2023, 9, 21, 13, 0),
    end: new Date(2023, 9, 21, 14, 0),
  },
  {
    title: "Conference",
    start: new Date(2023, 9, 22, 9, 0),
    end: new Date(2023, 9, 22, 17, 0),
  },
  {
    title: "Dentist Appointment",
    start: new Date(2023, 9, 23, 11, 0),
    end: new Date(2023, 9, 23, 12, 0),
  },
];

const DashboardPage = () => {
  return (
    <div className="mx-auto max-w-5xl py-10 flex flex-col">
      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
    </div>
  );
};

export default DashboardPage;
