const SvgIcon = ({ fill = "currentColor", size, height, width, ...props }) => {
  return (
    <svg
      fill={fill}
      height={size || height || 24}
      width={size || width || 24}
      viewBox="0 0 256 262"
      {...props}
    >
      <path
        d="M255.99 131.21c0-7.84-.63-15.36-1.8-22.68H130v42.93h70.43a60.39 60.39 0 01-26.18 39.65v32.96h42.4c24.77-22.77 39.05-56.4 39.05-92.86z"
        fill="#4285F4"
      />
      <path
        d="M130 261.45c35.1 0 64.59-11.66 86.13-31.64l-42.4-32.96c-11.69 7.84-26.63 12.7-43.73 12.7-33.58 0-62-22.65-72.25-53.14H14.21v33.26A130.03 130.03 0 00130 261.45z"
        fill="#34A853"
      />
      <path
        d="M57.75 156.41a77.79 77.79 0 010-49.82v-33.26H14.21a130.06 130.06 0 000 116.34l43.54-33.26z"
        fill="#FBBC05"
      />
      <path
        d="M130 50.23c18.73 0 35.56 6.45 48.79 19.08l36.57-36.57C192.58 15.25 165.1 4.4 130 4.4 80.23 4.4 35.06 32.3 14.21 73.33l43.54 33.26C66.97 73.88 96.39 50.23 130 50.23z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default SvgIcon;
