import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import PageTransition from "./pageTransition";

const Layout = () => {
  return (
    <div id="root" className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <PageTransition>
          <Outlet />
        </PageTransition>
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
