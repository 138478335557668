import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDakI07qZVJO06By5triHIAY1RIb1_EbqM",
  authDomain: "psycoplam-c4557.firebaseapp.com",
  projectId: "psycoplam-c4557",
  storageBucket: "psycoplam-c4557.firebasestorage.app",
  messagingSenderId: "103237127558",
  appId: "1:103237127558:web:ad1f3745fae932b83fb007",
  measurementId: "G-N900CB444J",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the auth object
export const auth = getAuth(app);
